var novaslider = {
	novaSlider: function () {
		if ($('.nova-slider-1 .list-thumb.owl-carousel').length) {
			$('.nova-slider-1 .list-thumb.owl-carousel').owlCarousel({
				autoWidth: true,
				center: true,
				nav: true,
				dots: false,
				loop: true,
				margin: 4,
				navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
				callbacks: true,
				item: 3,
				responsive: {
					480: {
						items: 1
					},
					768: {
						items: 2
					},
					992: {
						items: 3
					},
					1140: {
						items: 3
					}
				}
			});
		}
	}
};

export default novaslider;