var height = {
	matchHeight : function(){
		function changeItemBoxes4() {
			if ($(window).width() > 991) {
				var geth = $('.nova-gallery-3 .top-list').outerHeight();
				$('.nova-gallery-3 .part-list').each(function () {
					var countitem = $(this).find('.item').length;
					$(this).find('.item').each(function () {
						$(this).css({
							'height': 'calc(' + (geth / countitem) + 'px)'
							// 'height': '50%'
						});
					});
				});
			} else {
				$('.nova-gallery-3 .part-list .item ').each(function () {
					$(this).css({
						'height': 'initial'
					});
				});
			}
		}

		$(document).ready(function() {
			changeItemBoxes4();
		});

		$(window).resize(function () {
			changeItemBoxes4();
		});
	}
};
export default height;