// import bgimg form './modules/bgimg.js';
import bgimg from './modules/bgimg';
import  listtoselect from './modules/listtoselect';
import  novaslider from './modules/slider';
import  height from './modules/height';

$(document).ready(function(){
	bgimg.bgResponsive();
	listtoselect.listSelect();
	novaslider.novaSlider();
	height.matchHeight();
});

